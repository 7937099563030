
import { defineComponent } from 'vue';
import Filter from '@/components/search/Filter.vue';
import TextField from '@/components/form/TextField.vue';
import PreLoader from '@/components/PreLoader/PreLoader.vue';
import UiCombobox from '@/components/form/UiCombobox.vue';
import UiErrors from '@/components/form/UiErrors.vue';
import AuthService from '@/services/AuthService';
import AccountingService from '@/services/AccountingService';
import {
  User,
  Roles,
  ApiError,
} from '@/models/auth';
import {
  Company,
  CompanySearchParams,
} from '@/models/accounting';
import RolePermissions from '@/mixins/RolePermissions';

export default defineComponent({
  name: 'CreateUser',
  mixins: [RolePermissions],
  components: {
    Filter,
    TextField,
    PreLoader,
    UiCombobox,
    UiErrors,
  },
  data: () => ({
    userDetails: {
      firstName: '',
      Surname: '',
      email: '',
      role: '',
      companies: [] as Company[],
    },
    companies: [] as Company[],
    isPageLoaded: false,
    isLoading: false,
    isValidating: false,
    searchTimeoutId: 0,
    companySearchInput: '',
  }),
  async created() {
    const companyCode = process.env.VUE_APP_PRIORITIZE_COMPANY_CODE as string;

    if (companyCode) {
      try {
        const prioritizedCompany: Company = await AccountingService.showCompany(companyCode);

        this.addCompanies(prioritizedCompany);
      } catch (error) {
        this.isPageLoaded = false;
      }
    }

    const params: CompanySearchParams = {
      take: 10,
    };

    const companies = await AccountingService.listCompanies(params) as any;

    if (companies.data.length) {
      companies.data.forEach((company: Company) => this.addCompanies(company));
    }

    this.isPageLoaded = true;
  },
  computed: {
    emailValidationError() {
      const errors: string[] = [];

      let isValid = true;

      const regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

      if (this.userDetails.email === '') {
        errors.push('Email is required');
        isValid = false;
      }

      if (!regex.test(this.userDetails.email)) {
        errors.push('Email is not valid');
        isValid = false;
      }

      return { isValid, error: errors.join(' and ') };
    },
    roles(): any {
      return [
        {
          id: '0',
          title: Roles.SUPER_ADMIN,
        },
        {
          id: '1',
          title: Roles.ADMIN,
        },
        {
          id: '2',
          title: Roles.COMPANY_PRODUCER,
        },
      ]?.filter((r: any) => {
        if (r.id !== '0') return true;
        if (this.hasRole('super-admin')) return true;
        return false;
      });
    },
  },
  watch: {
    async companySearchInput(val) {
      if (!/\S/.test(val) && val !== '') {
        return;
      }

      window.clearTimeout(this.searchTimeoutId);

      this.searchTimeoutId = window.setTimeout(async () => {
        const params: CompanySearchParams = {
          page: 1,
          take: 10,
        };

        if (val !== '') {
          params.search_name = val;
        }

        const companies = await AccountingService.listCompanies(params) as any;

        this.companies = companies.data;
      }, 300);
    },
  },
  methods: {
    addCompanies(company: Company): void {
      if (company) {
        const isExists = this.companies.filter((filCom) => company.id === filCom.id).length;
        if (!isExists) this.companies.push(company);
      }
    },
    onCompanyRemoveHandler(index: number): void {
      this.$nextTick(() => this.userDetails.companies.splice(index, 1));
    },
    onRoleRemoveHandler(): void {
      (this.$refs.roleSelector as any).reset();
    },
    async onSaveUserHandler(): Promise<void> {
      this.isValidating = true;

      const condition = this.userDetails.firstName !== ''
        && this.userDetails.Surname !== ''
        && this.emailValidationError.isValid
        && this.userDetails.role !== '';

      if (condition) {
        this.isLoading = true;

        const specs = {
          first_name: this.userDetails.firstName,
          last_name: this.userDetails.Surname,
          email: this.userDetails.email,
          role: this.userDetails.role,
          enabled: true,
          companies: this.userDetails.companies.map((company) => company.id),
        };

        const response = await AuthService.createUser(specs);

        const apierror: ApiError = response as ApiError;
        const user: User = response as User;

        if (apierror?.message) {
          this.$toast.show(`Error: ${apierror.message}`, 3000);
        } else {
          this.$toast.show(`User ${user.first_name} ${user.last_name} created succefully`, 3000);

          this.$router.push({ name: 'ManageUsers' });
        }

        this.isLoading = false;

        this.isValidating = false;
      }
    },
    onRoleSelected(option: string): void {
      this.userDetails.role = option;
    },
  },
});
